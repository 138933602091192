import { createMetricsFactory } from 'metrics-js';
export const PPUIMetrics = createMetricsFactory('pricing-pages-ui');
export const UIAUMetrics = createMetricsFactory('ui-addon-upgrades', {
  library: 'ui-addon-upgrades'
});
export const trackTalkToSalesPql = app => {
  if (app === 'pricing-pages') {
    // For namespace-specific alerting monitorable by the pricing-pages team
    PPUIMetrics.counter('talk-to-sales-pql').increment();
  }
  UIAUMetrics.counter('talk-to-sales-pql', {
    app
  }).increment();
};
export const trackPqlFailure = app => {
  UIAUMetrics.counter('pql-failure', {
    app
  }).increment();
};
export const trackFailedApiNameMap = (location, apiName, upgradeProduct) => {
  if (location) {
    UIAUMetrics.counter('failed-api-name-map', {
      location,
      apiName,
      upgradeProduct
    }).increment();
  }
};
export const trackTrialActivation = (app, upgradeProduct) => {
  UIAUMetrics.counter('trial-activation', {
    app,
    upgradeProduct
  }).increment();
};
export const trackPurchaseMotionFailure = ({
  uniqueId,
  apiName,
  purchaseMotion
}) => {
  UIAUMetrics.counter('purchase-motion-failure', {
    uniqueId,
    apiName,
    purchaseMotion
  }).increment();
};
export const trackUpgradePointTooltipFailure = upgradeKey => {
  UIAUMetrics.counter('locked-feature-tooltip-failure', {
    upgradeKey
  }).increment();
};
export const incrementPurchaseOrderGenerationSuccess = ({
  type,
  apiNames
}) => {
  UIAUMetrics.counter('purchase-order-generation-success', {
    type,
    apiNames: apiNames || ''
  }).increment();
};
export const incrementPurchaseOrderGenerationFailure = ({
  type,
  apiNames
}) => {
  UIAUMetrics.counter('purchase-order-generation-failure', {
    type,
    apiNames: apiNames || ''
  }).increment();
};