'use es6';

import http from 'hub-http/clients/apiClient';
import { formatEmailCampaignRecord, formatEmailCampaigns } from 'reference-resolvers/formatters/formatEmailCampaigns';
import { Map as ImmutableMap } from 'immutable';
const BASE_URL = '/cosemail/v1/emails/search';
const TIMEOUT = 30000;
export const createGetEmailCampaignsByid = ({
  httpClient
}) => ids => httpClient.put(`${BASE_URL}/by-ids`, {
  data: {
    emailCampaignIds: ids
  }
}).then(response => ImmutableMap(response).map(formatEmailCampaignRecord));
export const getEmailCampaignsByid = createGetEmailCampaignsByid({
  httpClient: http
});
export const makeCreateGetEmailCampaignsSearchPage = (targetIdLevel = null) => ({
  httpClient
}) => query => {
  const parsedQuery = {
    query: {
      q: query.get('query') || '',
      offset: query.get('offset') || 0,
      limit: query.get('count') || 100
    },
    timeout: TIMEOUT
  };
  return httpClient.get(BASE_URL, parsedQuery).then(response => {
    const {
      hasMore,
      results = [],
      offset
    } = response;
    const filteredResults = targetIdLevel ? results.filter(r => r.idLevel === targetIdLevel) : results;
    return ImmutableMap(formatEmailCampaigns({
      count: filteredResults.length,
      hasMore,
      offset,
      results: filteredResults
    }));
  });
};
export const createGetEmailCampaignPage = makeCreateGetEmailCampaignsSearchPage();
export const getEmailCampaignPage = createGetEmailCampaignPage({
  httpClient: http
});