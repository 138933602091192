// errors thrown by hub-http add extra information to the error in hub-http#buildResponse
export const isHubHttpError = error => Number.isInteger(error === null || error === void 0 ? void 0 : error.status);

// errors thrown by client-types operations add the original hub-http error to the `error.cause` in createHttpRpcClientV2
export const isRpcError = error => !!error.cause && isHubHttpError(error.cause);

// recursively looks for the root error by following the cause property until it finds the lowest level error
// this is useful for chirp errors which get nested 2 levels deep
export const getRootError = error => error.cause ? getRootError(error.cause) : error;

// errors thrown by the chirp client when the BE returns a valid rpc error
export const isChirpError = error => 'chirpErrorMessage' in error && 'errorType' in error;
export const getChirpError = error => {
  const rootError = getRootError(error);
  if (isChirpError(rootError)) {
    return rootError;
  }
  return null;
};

// errors thrown by the chirp client when there is an unexpected BE internal error
export const isChirpInternalError = error => 'type' in error && error.type === 'INTERNAL_ERROR';
export const getChirpInternalError = error => {
  const rootError = getRootError(error);
  if (isChirpInternalError(rootError)) {
    return rootError;
  }
  return null;
};