import PortalIdParser from 'PortalIdParser';
import enviro from 'enviro';
import { stringify } from 'hub-http/helpers/params';
import { buildUrl, parseUrl } from 'hub-http/helpers/url';
import { getFullUrl } from 'hubspot-url-utils';
import { na1 } from 'hubspot-url-utils/hublets';
import devLogger from 'react-utils/devLogger';
import { CUSTOM_SSL, SANDBOX_LIMIT_INCREASE } from 'self-service-api/constants/ApiNames';
import { PROD, QA } from 'self-service-api/constants/Environments';
import { SALES_HUB_PROFESSIONAL } from '../../constants/ApiNames';
import { SALES_PROFESSIONAL } from '../../constants/UpgradeProducts';
const pid = PortalIdParser.get();
const baseUrlOptions = () => {
  const isQAProxy = window.location.hostname.indexOf('wthubspot') >= 0;
  const isQA = enviro.isQa() || isQAProxy;
  const baseUrl = getFullUrl('app', {
    envOverride: isQA ? QA : PROD
  });
  const {
    protocol,
    hostname
  } = parseUrl(baseUrl);
  return {
    protocol,
    hostname
  };
};
const formHref = getFullUrl('forms', {
  domainOverride: 'hsforms',
  hubletOverride: na1
});
const _createExternalUrl = (path = '', query = {}) => {
  const urlOptions = Object.assign({}, baseUrlOptions(), {
    path,
    query: stringify(query)
  });
  return buildUrl(urlOptions);
};
const _createProductsAndAddonsLink = (path = '', params) => {
  if (params && !params.upgradeSource) {
    devLogger.warn({
      message: `createProductsAndAddonsLink: Expected upgradeSource for path ${path} but got ${params.upgradeSource}`,
      key: `createProductsAndAddonsLink: ${path}`
    });
  }
  const urlOptions = Object.assign({}, baseUrlOptions(), {
    path: `/browse/${pid}/${path}`,
    query: stringify(params)
  });
  return buildUrl(urlOptions);
};
const _createPricingPageLink = (path = '', params, hash, isBeta = false) => {
  if (params && !params.upgradeSource) {
    devLogger.warn({
      message: `createPricingPageLink: Expected upgradeSource for path ${path} but got ${params.upgradeSource}`,
      key: `createPricingPageLink: ${path}`
    });
  }
  const urlOptions = Object.assign({}, baseUrlOptions(), {
    path: `/${pid ? 'pricing' : 'public-pricing'}${isBeta ? '-beta' : ''}/${pid ? `${pid}/` : ''}${path}`,
    query: params && stringify(params),
    hash: hash && hash.anchorLink ? hash.anchorLink : ''
  });
  return buildUrl(urlOptions);
};
export const redirectRoutes = {
  'landing-pages': `/pages/${pid}/manage/landing`,
  email: `/email/${pid}/manage`,
  'marketing-workflows': `/workflows/${pid}`,
  'content-strategy': `/content-strategy/${pid}/clusters`,
  'sales-workflows': `/workflows/${pid}`,
  playbooks: `/playbooks/${pid}`,
  sequences: `/sequences/${pid}`,
  quotes: `/quotes/${pid}`,
  tickets: `/contacts/${pid}/tickets/list/view/all`,
  feedback: `/feedback/${pid}/home`,
  'knowledge-base': `/knowledge/${pid}/insights/dashboard`,
  'service-workflows': `/workflows/${pid}`,
  meetings: `/meetings/${pid}`,
  'user-settings': `/settings/${pid}/users?createUserEmail`,
  social: `/social/${pid}/`,
  seo: `/content-strategy/${pid}/recommendations`,
  forecasting: `/forecasting/${pid}`,
  'live-messages-settings': `/live-messages-settings/${pid}`,
  'custom-coded-workflow-actions': `/workflows/${pid}`,
  'custom-coded-bot-actions': `/chatflows/${pid}`,
  webhooks: `/workflows/${pid}`,
  'data-quality-automation': `/workflows/${pid}`,
  campaigns: `/marketing/${pid}/campaigns`,
  datasets: `/advanced-builder/${pid}/dataset`,
  'snowflake-data-share': `/ecosystem/${pid}/marketplace/apps/productivity/connector/snowflake`,
  payments: `/settings/${pid}/sales/payments`
};
export const mapRedirectTargetToRoute = (redirectTarget, upgradeProduct) => {
  if (redirectTarget !== 'other' && redirectRoutes[redirectTarget]) {
    return redirectRoutes[redirectTarget];
  }
  const hub = upgradeProduct ? upgradeProduct.split('-')[0] : '';
  return upgradeProduct === SALES_PROFESSIONAL ? `/getting-started/${pid}` : `/reports-dashboard/${pid}/${hub}`;
};
export const mapRedirectTargetToRouteFromApiName = (redirectTarget, apiName) => {
  if (redirectTarget !== 'other' && redirectRoutes[redirectTarget]) {
    return redirectRoutes[redirectTarget];
  }
  const hub = apiName ? apiName.split('-')[0] : '';
  return apiName === SALES_HUB_PROFESSIONAL ? `/getting-started/${pid}` : `/reports-dashboard/${pid}/${hub}`;
};
export const salesPricing = upgradeSource => {
  return _createPricingPageLink(`sales`, {
    upgradeSource
  });
};
export const marketingPricing = upgradeSource => {
  return _createPricingPageLink(`marketing`, {
    upgradeSource
  });
};
export const servicePricing = upgradeSource => {
  return _createPricingPageLink(`service`, {
    upgradeSource
  });
};
export const starterContacts = () => {
  const urlOptions = Object.assign({}, baseUrlOptions(), {
    path: `/pricing/${pid}/marketing`
  });
  return buildUrl(urlOptions);
};
export const addonReporting = upgradeSource => {
  return _createProductsAndAddonsLink('upgrade/reporting', {
    upgradeSource
  });
};
export const addonAds = upgradeSource => {
  return _createPricingPageLink('marketing', {
    upgradeSource,
    products: ['marketing-hub-professional_1', 'ads-limit-increase_1']
  }, {
    anchorLink: 'calculator'
  });
};
export const addonCalling = upgradeSource => {
  return _createPricingPageLink('bundle', {
    upgradeSource,
    products: ['calling-limit-increase_1']
  });
};
export const addonPhone = upgradeSource => {
  return _createPricingPageLink('bundle', {
    upgradeSource,
    products: ['phone-limit-increase_1']
  });
};
export const addonTeams = upgradeSource => {
  return _createPricingPageLink('bundle', {
    upgradeSource,
    products: ['teams-limit-increase_1']
  });
};
export const addonWebsite = upgradeSource => {
  return _createProductsAndAddonsLink('compare/website', {
    upgradeSource
  });
};
export const addonWhatsapp = upgradeSource => {
  return _createPricingPageLink('bundle', {
    upgradeSource,
    products: ['whatsapp-limit-increase_1']
  });
};
export const addonMarketingSms = upgradeSource => {
  return _createPricingPageLink('bundle', {
    upgradeSource,
    products: ['marketing-sms_1']
  });
};
export const addonMarketingSmsShortCode = upgradeSource => {
  return _createPricingPageLink('bundle', {
    upgradeSource,
    products: ['marketing-sms-short-code_1']
  });
};
export const limitIncreaseMarketingSms = upgradeSource => {
  return _createPricingPageLink('bundle', {
    upgradeSource,
    products: ['marketing-sms-limit-increase_1']
  });
};
export const addonVideo = upgradeSource => {
  return _createPricingPageLink('bundle', {
    upgradeSource,
    products: ['video-limit-increase_1']
  });
};
export const limitIncreaseWorkflows = upgradeSource => {
  return _createPricingPageLink('bundle', {
    upgradeSource,
    products: ['workflows-limit-increase_1']
  });
};
export const limitIncreaseUsers = upgradeSource => {
  return _createPricingPageLink('bundle', {
    upgradeSource,
    products: ['users-limit-increase_1']
  });
};
export const limitIncreaseCustomObjects = upgradeSource => {
  return _createPricingPageLink('bundle', {
    upgradeSource,
    products: ['custom-objects-limit-increase_1']
  });
};
export const limitIncreaseCalculatedProperties = upgradeSource => {
  return _createPricingPageLink('bundle', {
    upgradeSource,
    products: ['calculated-properties-limit-increase_1']
  });
};
export const limitIncreaseCustomProperties = upgradeSource => {
  return _createPricingPageLink('bundle', {
    upgradeSource,
    products: ['custom-properties-limit-increase_1']
  });
};
export const limitIncreaseCrmObject = upgradeSource => {
  return _createPricingPageLink('bundle', {
    upgradeSource,
    products: ['crm-object-limit-increase_1']
  });
};
export const limitIncreaseCRMContactRecords = upgradeSource => {
  return _createPricingPageLink('bundle', {
    upgradeSource,
    products: ['non-marketing-contacts-limit-increase_1']
  });
};
export const cmsPricing = upgradeSource => {
  return _createPricingPageLink(`cms`, {
    upgradeSource
  });
};
export const operationsPricing = upgradeSource => {
  return _createPricingPageLink(`operations`, {
    upgradeSource
  });
};
export const marketingPlusPricing = upgradeSource => {
  return _createPricingPageLink(`marketing-plus`, {
    upgradeSource
  });
};
export const breezeIntelligencePricing = upgradeSource => {
  return _createPricingPageLink(`breeze-intelligence`, {
    upgradeSource
  });
};
export const addonCrm = upgradeSource => {
  return _createPricingPageLink('crm', {
    upgradeSource
  });
};
export const addonBrandDomain = upgradeSource => {
  return _createProductsAndAddonsLink('upgrade/brand-domain', {
    upgradeSource
  });
};
export const addonDedicatedIp = upgradeSource => {
  return _createProductsAndAddonsLink('services/dedicated-ip', {
    upgradeSource
  });
};
export const addonAdditionalPortalLink = upgradeSource => {
  return _createProductsAndAddonsLink('services/add-on-portal', {
    upgradeSource
  });
};
export const addonTransactionalEmail = upgradeSource => {
  return _createProductsAndAddonsLink('services/transactional-email', {
    upgradeSource
  });
};
export const checkout = (orderId, upgradeSource) => _createExternalUrl(`/checkout/${pid}/purchases/${orderId}${upgradeSource ? `?upgradeSource=${upgradeSource}` : ''}`);
export const getPaidUserManagementUrl = () => {
  return _createExternalUrl(`/settings/${pid}/users/seats`);
};
export const serviceDesignatedTechnicalSupport = upgradeSource => {
  return `https://www.hubspot.com/services/consulting/technical?source=${upgradeSource}`;
};
export const serviceInPersonTraining = upgradeSource => {
  return `https://www.hubspot.com/services/classroom-training?source=${upgradeSource}`;
};
export const serviceStarterKit = upgradeSource => {
  return _createProductsAndAddonsLink('services/starter-kit', {
    upgradeSource
  });
};
export const salesProfessionalOnboarding = upgradeSource => {
  return _createProductsAndAddonsLink('services/sales-professional-onboarding', {
    upgradeSource
  });
};
export const serviceProfessionalOnboarding = upgradeSource => {
  return _createProductsAndAddonsLink('services/service-professional-onboarding', {
    upgradeSource
  });
};
export const inboundConsultingBlock = upgradeSource => {
  return _createProductsAndAddonsLink('services/inbound-consulting-block', {
    upgradeSource
  });
};
export const technicalConsultingBlock = upgradeSource => {
  return _createProductsAndAddonsLink('services/technical-consulting-block', {
    upgradeSource
  });
};
export const productsAndAddons = (isBeta = false) => {
  return _createPricingPageLink('', undefined, {}, isBeta);
};

// This paywall is where you'd go to upgrade to Partner status
export const partnerProgramPaywall = () => {
  const urlOptions = Object.assign({}, baseUrlOptions(), {
    path: `/upgrade/${pid}/partner-program`
  });
  return buildUrl(urlOptions);
};
export const suite = upgradeSource => {
  return _createPricingPageLink(`suite`, {
    upgradeSource
  });
};

//include limits when going to the PPP
export const suiteStarter = (upgradeSource, params) => {
  return _createPricingPageLink(`suite-starter`, Object.assign({
    upgradeSource
  }, params));
};
export const getUpgradePageUrl = (upgradeSource, params) => {
  return _createPricingPageLink(`upgrade`, Object.assign({
    upgradeSource
  }, params));
};
export const trialPricingPage = (upgradeSource, apiName) => {
  return _createPricingPageLink(`trial-upgrade`, {
    upgradeSource,
    apiName
  });
};

//EBX-642 professional comparison page
export const proComparePage = upgradeSource => {
  return _createPricingPageLink(`compare-professional`, {
    upgradeSource
  });
};
export const bundle = upgradeSource => {
  return _createPricingPageLink('bundle', {
    upgradeSource
  });
};
export const addonCustomSsl = upgradeSource => _createPricingPageLink('bundle', {
  upgradeSource,
  selectedAddOns: CUSTOM_SSL
});
export const addonSandbox = upgradeSource => _createPricingPageLink('bundle', {
  upgradeSource,
  selectedAddOns: SANDBOX_LIMIT_INCREASE
});
export const getAccountDashboardUrl = () => _createExternalUrl(`/account-and-billing/${PortalIdParser.get()}`);
export const formUrl = ({
  portalId,
  formId
}) => {
  return `${formHref}/submissions/v3/integration/submit/${portalId}/${formId}`;
};