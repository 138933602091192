import events from 'ui-addon-upgrades/events.yaml';
import { createTracker } from 'usage-tracker';
import { isApiBlackListed } from '../navigation/isApiBlackListed';
const AMPLITUDE_APP_NAME = 'upgrades';
export const tracker = createTracker({
  events,
  properties: {
    namespace: AMPLITUDE_APP_NAME
  }
});
const _cleanUpEventProperties = eventProperties => {
  if (!eventProperties) return {};
  Object.keys(eventProperties).forEach(property => {
    if (eventProperties[property] === null || eventProperties[property] === undefined) {
      delete eventProperties[property];
    }
  });
  return eventProperties;
};
export const track = (eventKey, eventProperties) => {
  if (isApiBlackListed()) {
    return;
  }
  const cleanedUpEventProperties = _cleanUpEventProperties(eventProperties);
  tracker.track(eventKey, cleanedUpEventProperties);
};