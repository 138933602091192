import Raven from 'raven-js';
import { useCallback, useEffect, useMemo } from 'react';
import { submitInteraction } from 'self-service-api/api/submitInteraction';
import { UpgradeMessageEventKeys } from 'ui-addon-upgrades/_core/common/constants/upgradeMessageEventKeys';
import { track } from '../_core/common/eventTracking/tracker';
import { openUpgradePageInNewTab } from '../_core/utils/upgradePageRedirect';
import useErrorAlert from './useErrorAlert';
import { getUpgradeConfigTrackingProperties, getUpgradePointPromise, updateViewedUpgradePointsCache, warnArchivedUpgradePointUse } from './utils';
const logUseUpgradeModalTryCatchError = ({
  customHookName = 'useUpgradeModal',
  error,
  message,
  upgradeKey
}) => {
  if (error instanceof Error) {
    Raven.captureException(new Error(`${customHookName} tryCatch error: ${message}`), {
      extra: {
        message: error.message,
        upgradeKey
      }
    });
  }
};
/**
 * ## Upgrade Management System
 *
 * `useUpgradeModal` takes your upgrade key as an argument and returns a callback
 * to open the Upgrade Page. Upgrade keys can be created and managed in the
 * [Upgrade Management System](<https://tools.hubteamqa.com/ums>) (`go/ums`).
 *
 * @example
 * ```tsx
 * const YourComponent = () => {
 *  const { openUpgradeModal } = useUpgradeModal('your-upgrade-key');
 *
 *  return <UIButton onClick={openUpgradeModal} />;
 * }
 *
 * @deprecated
 * Replace with useUpgradePoint from ui-addon-upgrades
 * ```
 */
export function useUpgradeModal(upgradeKey, isFeatureLocked = true) {
  const {
    setShowErrorAlert
  } = useErrorAlert();
  const upgradePointPromise = useMemo(() => {
    return getUpgradePointPromise({
      upgradeKey,
      delayCall: true
    });
  }, [upgradeKey]);

  // Fetch upgrade config and track view
  useEffect(() => {
    if (isFeatureLocked && upgradeKey) {
      upgradePointPromise.then(upgradeConfig => {
        if (!upgradeConfig) return;
        updateViewedUpgradePointsCache(upgradeKey, () => {
          track('interaction', Object.assign({
            action: 'viewed upgrade point'
          }, getUpgradeConfigTrackingProperties(upgradeConfig)));
          if (upgradeConfig.status === 'ARCHIVED') {
            warnArchivedUpgradePointUse(upgradeConfig.upgradeKey);
          }
        });
      }).catch(err => {
        console.error(err);
      });
    }
  }, [isFeatureLocked, upgradeKey, upgradePointPromise]);

  // Track click and set state
  const openUpgradeModal = useCallback(() => {
    try {
      if (!isFeatureLocked) {
        return;
      }
      openUpgradePageInNewTab(upgradeKey);
      upgradePointPromise.then(upgradeConfig => {
        if (!upgradeConfig) return;
        track('interaction', Object.assign({
          action: 'clicked upgrade point'
        }, getUpgradeConfigTrackingProperties(upgradeConfig)));
        submitInteraction(upgradeKey).catch(error => Raven.captureException(Error('Failed to submit interaction in locked feature'), {
          extra: {
            message: error.message,
            upgradeKey
          }
        }));
      }).catch(__error => {
        setShowErrorAlert(true);
      });
    } catch (error) {
      setShowErrorAlert(true);
      logUseUpgradeModalTryCatchError({
        error,
        message: 'An error occured in defining openUpgradeModal',
        upgradeKey
      });
    }
  }, [isFeatureLocked, setShowErrorAlert, upgradeKey, upgradePointPromise]);
  return {
    openUpgradeModal
  };
}
export function useUpgradeModalInIframe(upgradeKey, isFeatureLocked = true) {
  // Post message to useUpgradeEventListener
  useEffect(() => {
    try {
      window.top.postMessage({
        modalKey: upgradeKey,
        event: UpgradeMessageEventKeys.FETCH_UPGRADE_POINT,
        isFeatureLocked
      }, '*');
    } catch (error) {
      logUseUpgradeModalTryCatchError({
        customHookName: 'useUpgradeModalInIframe',
        error,
        message: 'An error occured with posting the message to the window',
        upgradeKey
      });
    }
  }, [upgradeKey, isFeatureLocked]);

  // Handle opening the modal
  const openUpgradeModal = useCallback(() => {
    try {
      if (!isFeatureLocked) {
        return;
      }

      // Need to send post message so that the "upgrade point clicked" tracking event can fire
      window.top.postMessage({
        upgradeKey,
        event: UpgradeMessageEventKeys.OPEN_UPGRADE_PAGE,
        isFeatureLocked
      }, '*');
    } catch (error) {
      logUseUpgradeModalTryCatchError({
        customHookName: 'useUpgradeModalInIframe',
        error,
        message: 'An error occured in defining openUpgradeModal',
        upgradeKey
      });
    }
  }, [isFeatureLocked, upgradeKey]);

  // return the callback for use in app
  return {
    openUpgradeModal
  };
}